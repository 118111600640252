<script lang="ts" setup>
defineProps({
  lg: Boolean,
  active: Boolean,
  showLoading: Boolean,
  noBgColor: Boolean,
})

const noBgColor = computed(() => {
  return 'bg-none'
})

</script>

<template>
  <div
    class="group w-full cursor-pointer textmode-neutral-800 rounded-lg"
    :class="{
      'border-primaryLight-600 dark:border-primaryDark-400 border-opacity-40 border': active,
      'border-transparent opacity-50 hover:border-opacity-40 hover:border-primaryLight-600 dark:hover:border-primaryDark-400 hover:opacity-100 boxshadow':
        !active,
      'px-4 py-5': lg,
      'p-3 lg:px-4 lg:py-5': !lg
    }"
  >
    <div
      class="h-full flex flex-col"
      :class="{
        'min-w-2xs sm:min-w-full': lg
      }"
    >
      <slot name="subtitle" />

      <div
        class="flex items-center h-full gap-4"
        :class="{
          'justify-between': lg
        }"
      >
        <AppSpinner v-if="showLoading" />

        <div
          v-else
          class="rounded-full mr-4 flex items-center justify-center"
          :class="[
            lg ? 'min-w-12 w-12 h-12' : 'w-4 h-4 md:w-6 md:h-6',
            {
              'textmode': active,
              'textmode-neutral': !active
            }
          ]"
        >
          <slot name="icon" />
        </div>

        <slot />
      </div>
    </div>
  </div>
</template>
