<script lang="ts" setup>
import type { Token } from '@injectivelabs/token-metadata'
import { PropType } from 'vue'
import { ActivityTab } from '@/types'

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },

  tab: {
    type: String as PropType<ActivityTab>,
    required: true
  },

  tokens: {
    type: Array as PropType<Token[]>,
    required: true
  }
})

const emit = defineEmits<{
  (e: 'update:modelValue', state: string): void
}>()

const options = computed(() =>
  props.tokens.map((token) => ({
    token,
    display: token.symbol,
    value: token.denom
  }))
)

const value = computed({
  get: (): string => props.modelValue,
  set: (val: string) => {
    emit('update:modelValue', val)
  }
})
</script>

<template>
  <AppSelectField
    v-model="value"
    :options="options"
    :placeholder="
      tab === ActivityTab.WalletHistory
        ? $t('walletHistory.transfers.asset')
        : $t('account.positions.market.label')
    "
    searchable
    clearable
    data-cy="universal-table-filter-by-asset-input"
    class="bg-white dark:bg-gray-1000 rounded-3xl"
  >
    <template #selected-option="{ option }">
      <PartialsAccountPositionsFilterOption v-if="option" :option="option" />
    </template>

    <template #option="{ option, active }">
      <PartialsAccountPositionsFilterOption :option="option" :active="active" />
    </template>
  </AppSelectField>
</template>
